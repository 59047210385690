import gsap from "gsap"
import { sleep } from "library/functions"
import ImageSequence from "library/ImageSequence"
import { transitionAwaitPromise } from "library/Loader"
import {
  registerTransition,
  unregisterTransition,
} from "library/Loader/TransitionUtils"
import { useEffect, useState } from "react"
import styled from "styled-components"
import media from "styles/media"
import { CustomEase } from "types/CustomEase"

interface SpinnerProps {
  show: boolean
}

const IMAGE_WIDTH = 300
const WRAPPER_WIDTH = 500
const spinnerDuration = 3
const inDuration = 0.5
const overlap = 0.3

export default function Spinner({ show }: SpinnerProps) {
  const [frame, setFrame] = useState(0)

  const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
  const [circle, setCircle] = useState<HTMLDivElement | null>(null)
  const [square, setSquare] = useState<HTMLDivElement | null>(null)
  const [triangle, setTriangle] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!wrapper || !circle || !square || !triangle || !show) return
    const allShapes = [triangle, square, circle]
    const frameWrap = { frame: 0 }
    let isMounted = true

    /**
     * don't allow the loader to finish in the middle of a loop
     * always aim for the end of the animation
     */
    const onStartAndRepeat = () => {
      const blankTime = 100
      if (isMounted) {
        transitionAwaitPromise(
          sleep(timeline.duration() * 1000 - blankTime * 2),
        )
      }
    }

    /**
     * the loop
     */
    const timeline = gsap
      .timeline({
        repeat: -1,
        onRepeat: onStartAndRepeat,
        onStart: onStartAndRepeat,
        delay: 0.1,
      })

      /**
       * Animate in
       */
      .fromTo(
        allShapes,
        {
          rotate: -180,
          scale: 0.1,
          opacity: 0,
        },
        {
          rotate: 0,
          scale: 1,
          opacity: 1,
          ease: "power3.out",
          stagger: 0.1,
          duration: inDuration,
          onStart: () => {
            setFrame(0)
          },
        },
      )

      /**
       * spin
       */
      .to(
        frameWrap,
        {
          frame: 120,
          snap: "frame",
          duration: spinnerDuration,
          onUpdate: () => {
            setFrame(frameWrap.frame)
          },
          ease: "linear",
        },
        inDuration - overlap,
      )
      .to(
        wrapper,
        {
          rotate: 360,
          duration: spinnerDuration,
          ease: CustomEase.create(
            "custom",
            "M0,0,C0.21,0.012,0.296,0.178,0.4,0.5,0.563,1.006,0.744,1,1,1",
          ),
        },
        "<",
      )

      /**
       * Animate out
       */
      .to(
        allShapes,
        {
          rotate: 180,
          scale: 0.1,
          opacity: 0,
          ease: "power3.in",
          stagger: 0.2,
          duration: inDuration,
        },
        `-=${overlap + 0.6}`,
      )

    /**
     * always play at least once
     */
    transitionAwaitPromise(sleep(1000))

    /**
     * animate out before reverting
     */
    const revertTimeline = () => {
      timeline.pause()
      gsap.to(allShapes, {
        rotate: 180,
        scale: 0.1,
        opacity: 0,
        onComplete: () => {
          timeline.revert()
        },
      })
    }

    /**
     * make sure to animate out and revert when the out transition plays
     * we don't want the spinner to keep spinning when it slides away
     */
    registerTransition("slide", {
      in: () => {
        // pass
      },
      out: revertTimeline,
      inDuration: 0,
      outDuration: 0,
    })

    return () => {
      unregisterTransition("slide", [revertTimeline])
      isMounted = false
      setTimeout(() => {
        revertTimeline()
        timeline.revert()
      }, 1000)
    }
  }, [circle, show, square, triangle, wrapper])

  const sharedProps = {
    length: 120,
    frame,
    width: IMAGE_WIDTH,
    height: IMAGE_WIDTH,
  }

  return (
    <AnimationWrapper ref={setWrapper}>
      <SequenceWrapper
        ref={setCircle}
        style={{
          left: 0,
          top: 0,
        }}
      >
        <ImageSequence
          type="manual"
          folder="transition/GetToWork"
          {...sharedProps}
        />
      </SequenceWrapper>
      <SequenceWrapper
        ref={setSquare}
        style={{
          right: 0,
          top: 0,
        }}
      >
        <ImageSequence
          type="manual"
          folder="transition/The Details"
          {...sharedProps}
        />
      </SequenceWrapper>
      <SequenceWrapper
        ref={setTriangle}
        style={{
          right: (WRAPPER_WIDTH - IMAGE_WIDTH) / 2,
          bottom: 0,
        }}
      >
        <ImageSequence
          type="manual"
          folder="transition/TheBoss"
          {...sharedProps}
        />
      </SequenceWrapper>
    </AnimationWrapper>
  )
}

const AnimationWrapper = styled.div`
  position: relative;
  width: ${WRAPPER_WIDTH}px;
  height: ${WRAPPER_WIDTH}px;

  ${media.mobile} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(0.5);
  }
`

const SequenceWrapper = styled.div`
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_WIDTH}px;
  position: absolute;
  opacity: 0;
`
