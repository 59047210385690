import { graphql, useStaticQuery } from "gatsby"
import { createContext, useEffect, useMemo, useRef, useState } from "react"
import { CaseStudyImage } from "types/gatsby-aliases"

export const NextCaseContext = createContext<{
  validCases: NextCase[]
  setValidCases: React.Dispatch<React.SetStateAction<NextCase[]>>
}>({
  validCases: [],
  setValidCases: () => false,
})

export interface NextCase {
  slug: string | null
  nextCaseDarkText: boolean | null
  nextCaseImage: CaseStudyImage | null
  nextCaseBgColor: {
    hexCode: string | null
  } | null
}

export function NextCaseProvider({ children }: { children: React.ReactNode }) {
  const data: Queries.NextCaseQuery = useStaticQuery(graphql`
    query NextCase {
      allContentfulCaseStudy(
        sort: { order: ASC }
        filter: { id: { ne: "7689ddb0-1178-514e-a52e-187388f89380" } }
      ) {
        nodes {
          slug
          nextCaseDarkText
          nextCaseImage {
            ...ContentfulImageQuery
          }
          nextCaseBgColor {
            hexCode
          }
        }
      }
    }
  `)

  const allCases = data.allContentfulCaseStudy.nodes.map(node => {
    return node
  })

  const allValidCases = useRef(allCases)
  const [validCases, setValidCases] = useState(allCases)

  useEffect(() => {
    if (validCases.length === 0) {
      setValidCases(allValidCases.current)
    }
  }, [validCases])

  const nextCaseValue = useMemo(() => {
    return { validCases, setValidCases }
  }, [validCases, setValidCases])

  return (
    <NextCaseContext.Provider value={nextCaseValue}>
      {children}
    </NextCaseContext.Provider>
  )
}
