const colors = {
  pureWhite: "#FFFFFF",
  hotWhite: "#FEFCF8",
  base800: "#020207",
  base700: "#0C092F",
  base600: "#171626",
  base500: "#231F51",
  base400: "#28263A",
  base300: "#4E4B74",
  base200: "#837EB8",
  laserBlue400: "#6DF6FF",
} as const

export default colors
