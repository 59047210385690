import { FontsContext } from "components/Providers/fonts"
import gsap from "gsap"
import ScrollSmoother from "gsap/ScrollSmoother"
import ScrollTrigger from "gsap/ScrollTrigger"
import { useContext, useEffect, useState } from "react"
import { useDeepCompareMemo } from "use-deep-compare"
import animations, { isAnimationName } from "utils/animations"

import loader from "../library/Loader"
import useAnimation from "../library/useAnimation"

type Options = Record<string, Option> | null

interface Option {
  delay?: number
  triggerStart?: string
}

const selectEls = (wrapperEl: (HTMLElement | null)[]) => {
  const els: HTMLElement[] = []
  wrapperEl.forEach(el => {
    if (el) {
      const validEls = el.querySelectorAll<HTMLElement>(".animate")
      els.push(...validEls)
    }
  })
  return els
}

/**
 * A hook that finds and animates element within a given wrapper
 * animation classnames to add to child elements: fromLeft, blockUp, maskUp, headerUp.
 * any newly added animations can be added without needing to update types.
 * MUST also add the "animate" class to each element (e.g. "animate fromLeft")
 * fromLeft?: { delay?: number; triggerStart?: string }
 * blockUp?: { delay?: number; triggerStart?: string }
 * maskUp?: { delay?: number; triggerStart?: string }
 * headerUp?: { delay?: number; triggerStart?: string }
 * headerUpDelay?: number
 * @param wrapperEl - the element to search for children to animate.
 * if given an array, the first wrapper will be used as a shared trigger
 * @param options - optional options object with the following options:
 * @param options.delay - delay before animation starts
 * @param options.triggerStart - the gsap trigger start, i.e. "top 80%"
 * */
const useStandardAnimation = (
  wrapperEl: HTMLElement | (HTMLElement | null)[] | null,
  options?: Options,
  pinnedContainer?: HTMLElement | null,
) => {
  const fontsLoaded = useContext(FontsContext)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [triggers] = useState<Record<string, gsap.core.Timeline | undefined>>(
    {},
  )

  const [random, setRandom] = useState(0)
  const stableOptions = useDeepCompareMemo(() => options, [options])
  const stableWrapperEl = useDeepCompareMemo(() => wrapperEl, [wrapperEl])
  const stablePinnedContainer = useDeepCompareMemo(
    () => pinnedContainer ?? undefined,
    [pinnedContainer],
  )

  /**
   * fallback, just in case we get stuck
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      setPageLoaded(true)
    }, 5000)

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    const onStart = () => {
      setPageLoaded(false)
    }
    const onEnd = () => {
      setPageLoaded(true)
    }

    loader.addEventListener("anyStart", onStart)
    loader.addEventListener("anyEnd", onEnd)
    return () => {
      loader.removeEventListener("anyStart", onStart)
      loader.removeEventListener("anyEnd", onEnd)
    }
  }, [])

  /**
   * we create these animations ahead of time for performance reasons,
   * and immediately disable them so they don't run before they're visible
   * then, reenable them when they're visible
   *
   * this is better than waiting until they're visible to create them
   */
  useAnimation(() => {
    if (stableWrapperEl) {
      const isArray = Array.isArray(stableWrapperEl)
      const allValidElements = isArray
        ? selectEls(stableWrapperEl)
        : stableWrapperEl.querySelectorAll(".animate")

      allValidElements.forEach((element, index) => {
        const animationClass = [...element.classList].find(isAnimationName)

        if (!animationClass) {
          console.error("no animation class found")
          return
        }

        const optionsKey = animationClass
        const option: Option = stableOptions?.[optionsKey] ?? {}
        const animation = animations[animationClass]

        const delay = option.delay ?? 0.2
        const startValue =
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          ("triggerStart" in option && option.triggerStart) || "top 80%"

        triggers[startValue] ||= gsap.timeline({
          scrollTrigger: {
            trigger: stableWrapperEl,
            start: startValue,
            pinnedContainer: stablePinnedContainer,
          },
        })

        triggers[startValue]?.fromTo(
          element,
          animation.from,
          animation.to,
          delay + index * 0.2,
        )

        triggers[startValue]?.scrollTrigger?.disable()
        setRandom(Math.random())
      })
    }
  }, [stableOptions, triggers, stableWrapperEl, stablePinnedContainer])

  useEffect(() => {
    if (!fontsLoaded) return
    if (!pageLoaded) return
    if (random === 0) return

    const currentScroll = ScrollSmoother.get()?.scrollTop() ?? 0
    Object.values(triggers).forEach(trigger => {
      trigger?.scrollTrigger?.enable(true)
    })
    ScrollTrigger.refresh()
    ScrollSmoother.get()?.scrollTop(currentScroll)
  }, [fontsLoaded, pageLoaded, triggers, random])
}

export default useStandardAnimation
