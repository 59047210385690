import { useBackButton } from "library/Loader/TransitionUtils"
import { useTrackPageReady } from "library/pageReady"
import Scroll from "library/Scroll"
import useCSSHeightVariables from "library/useCssHeightVariables"
import { lazy, Suspense, useState } from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import colors from "styles/colors"
import useStandardAnimation from "utils/useStandardAnimation"

const Header = lazy(() => import("components/Header"))
const Menu = lazy(() => import("components/Header/Menu"))
const NavLinks = lazy(() => import("components/Header/NavLinks"))
const Footer = lazy(() => import("components/Footer"))

interface LayoutProps {
  children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  const [headerWrapper, setAniWrapperRef] = useState<HTMLElement | null>(null)
  const [contentWrapper, setAniWrapperRef1] = useState<HTMLElement | null>(null)

  useTrackPageReady()
  useBackButton()
  useCSSHeightVariables()

  useStandardAnimation([headerWrapper, contentWrapper], {
    blockUp: { delay: 0.05 },
  })

  return (
    <>
      <GlobalStyles />
      <AnimationWrapper ref={setAniWrapperRef}>
        <Suspense fallback={null}>
          <Header />
        </Suspense>
        <Suspense fallback={null}>
          <Menu />
        </Suspense>
      </AnimationWrapper>
      <Scroll>
        <AnimationWrapper ref={setAniWrapperRef1}>
          <Suspense fallback={null}>
            <NavLinks />
          </Suspense>
        </AnimationWrapper>
        <Main>
          {children}
          <Suspense fallback={null}>
            <Footer />
          </Suspense>
        </Main>
      </Scroll>
    </>
  )
}

const AnimationWrapper = styled.div``

const Main = styled.main`
  background: ${colors.base800};
  overflow: hidden;
  width: 100%;
`

const globalStyle = css`
  * {
    text-rendering: geometricprecision;
  }

  /** restore default focus states for elements that need them */
  *:focus-visible {
    outline: 2px solid ${colors.laserBlue400}88;
  }
`
const GlobalStyles = createGlobalStyle`${globalStyle}`
