export type Animations = Record<string, Animation>

export interface Animation {
  from: gsap.TweenVars
  to: gsap.TweenVars
}

const animations = {
  fromLeft: {
    from: {
      opacity: 0,
      x: "-100%",
    },
    to: {
      opacity: 1,
      x: 0,
      ease: "power1.inOut",
      duration: 0.5,
    },
  },
  blockUp: {
    from: {
      opacity: 0,
      scaleY: 2,
      transformOrigin: "top center",
    },
    to: {
      opacity: 1,
      scaleY: 1,
      transformOrigin: "top center",
      duration: 0.5,
      ease: "power1.inOut",
    },
  },
  maskUp: {
    from: {
      opacity: 0,
      y: "100%",
    },
    to: {
      opacity: 1,
      y: 0,
      duration: 0.5,
      ease: "power1.inOut",
    },
  },
  headerUp: {
    from: {
      opacity: 0,
      scaleY: 3,
      y: 60,
      transformOrigin: "top center",
    },
    to: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      stagger: 0.15,
      transformOrigin: "top center",
      duration: 0.5,
      ease: "power1.inOut",
    },
  },
  heroUp: {
    from: { opacity: 0, scaleY: 2 },
    to: {
      y: "-=75%",
      opacity: 1,
      scaleY: 1,
      duration: 0.8,
      ease: "power1.inOut",
    },
  },
  drawSig: {
    from: { drawSVG: "0 0%" },
    to: { drawSVG: "100%", duration: 0.8 },
  },
} as const satisfies Animations

export default animations

type AnimationName = keyof typeof animations
export const isAnimationName = (name: string): name is AnimationName => {
  return name in animations
}
