import gsap from "gsap"
import ScrollSmoother from "gsap/ScrollSmoother"
import {
  registerTransition,
  unregisterTransition,
} from "library/Loader/TransitionUtils"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

import Spinner from "./Spinner"

const duration = 1

export default function Transition() {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [showSpinner, setShowSpinner] = useState(false)

  const slideIn = () => {
    ScrollSmoother.get()?.paused(true)

    gsap.set(wrapperRef.current, { display: "grid" })

    const radius = Math.max(window.innerWidth, window.innerHeight)
    gsap.fromTo(
      wrapperRef.current,
      {
        yPercent: 100,
        y: 0,
        borderRadius: `${radius}px ${radius}px 0 0`,
      },
      {
        yPercent: 0,
        y: 0,
        duration,
        ease: "power3.out",
        borderRadius: "0 0 0 0",
        onComplete: () => setShowSpinner(true),
      },
    )
  }

  const slideOut = () => {
    setShowSpinner(false)
    const radius = Math.max(window.innerWidth, window.innerHeight)
    window.scrollTo(0, 0)
    ScrollSmoother.get()?.scrollTo(0)
    ScrollSmoother.get()?.paused(true)

    gsap.fromTo(
      wrapperRef.current,
      {
        yPercent: 0,
        y: 0,
        borderRadius: "0 0 0 0",
      },
      {
        yPercent: -100,
        y: 0,
        duration,
        ease: "power3.in",
        borderRadius: `0 0 ${radius}px ${radius}px`,
      },
    )
    // gsap can get reverted before the unpause would run, so use a timeout to prevent that
    setTimeout(() => {
      ScrollSmoother.get()?.paused(false)
    }, duration * 1000)
  }

  useEffect(() => {
    // register two page transitions
    registerTransition("slide", {
      in: slideIn,
      out: slideOut,
      inDuration: duration,
      outDuration: duration,
    })

    return () => {
      unregisterTransition("fade", [slideIn, slideOut])
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Spinner show={showSpinner} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateY(-200%);
  background-color: ${colors.base700};
  z-index: 100;
  pointer-events: none;
  display: none;
  place-items: center;
  ${textStyles.h1}
`
