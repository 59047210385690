import gsap from "gsap"
import { CustomEase as RawCE } from "gsap/CustomEase"

const CE = RawCE as {
  create: (name: string, data: unknown) => gsap.EaseFunction
}
gsap.registerPlugin(CE)

const CustomEase = {
  create: CE.create,
}

export default CustomEase
export { CustomEase }
