import loader from "library/Loader"
import { createContext, useEffect, useMemo, useState } from "react"

export const NavContext = createContext<{
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}>({
  open: false,
  setOpen: () => false,
})

interface Props {
  children: React.ReactNode
}

export function NavProvider({ children }: Props) {
  const [open, setOpen] = useState<boolean>(false)

  const navValue = useMemo(() => {
    return { open, setOpen }
  }, [open, setOpen])

  useEffect(() => {
    const closeNav = () => {
      setTimeout(() => {
        setOpen(false)
      }, 1000)
    }
    const closeImmediate = () => {
      setOpen(false)
    }

    loader.addEventListener("anyStart", closeNav)
    loader.addEventListener("scrollToTop", closeImmediate)
    return () => {
      loader.removeEventListener("anyStart", closeNav)
      loader.removeEventListener("scrollToTop", closeImmediate)
    }
  }, [open])

  return <NavContext.Provider value={navValue}>{children}</NavContext.Provider>
}
