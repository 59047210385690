import gsap from "gsap"
import ScrollSmoother from "gsap/ScrollSmoother"
import { ReactComponent as DetailsSVG } from "images/global/details.svg"
import { ReactComponent as PreloaderMarkSVG } from "images/global/preloader-mark.svg"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import {
  registerLoaderCallback,
  unregisterLoaderCallback,
} from "library/Loader/LoaderUtils"
import useAnimation from "library/useAnimation"
import { useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"
import media from "styles/media"
import textStyles from "styles/text"

export default function Preloader({ children }: { children: React.ReactNode }) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const boxRef = useRef<HTMLDivElement>(null)
  const filledMarquee = useRef<HTMLDivElement>(null)
  const [showContent, setShowContent] = useState(false)

  useAnimation(() => {
    window.scrollTo(0, 0)
    ScrollSmoother.get()?.scrollTo(0)
    setShowContent(true)
    const tl = gsap.timeline({
      defaults: {
        ease: "power1.inOut",
      },
    })
    tl.set(boxRef.current, { opacity: 1 }, 1)
    tl.from(boxRef.current, { width: 0 })
    tl.fromTo(boxRef.current, { height: 0 }, { height: "auto" })
    tl.to(null, { duration: 1 })

    const finishAnimation = () => {
      window.scrollTo(0, 0)
      ScrollSmoother.get()?.scrollTo(0)
      ScrollSmoother.get()?.paused(true)

      tl.to(filledMarquee.current, {
        clipPath: "circle(80% at 50% 50%)",
        duration: 0.7,
        ease: "power1.out",
      })
      tl.to(boxRef.current, { height: 0 })
      tl.to(boxRef.current, { width: 0 })
      tl.set(boxRef.current, { opacity: 0 })
      tl.to(wrapperRef.current, {
        yPercent: -100,
        duration: 0.7,
        ease: "power3.in",
        borderRadius: `0 0 ${window.innerWidth / 2}px ${
          window.innerWidth / 2
        }px`,
      })
      tl.call(() => {
        ScrollSmoother.get()?.paused(false)
      })
      tl.set(wrapperRef.current, { display: "none" })
    }

    registerLoaderCallback({
      callback: finishAnimation,
      duration: 3.4,
    })
    return () => {
      unregisterLoaderCallback(finishAnimation)
    }
  }, [])

  return (
    <>
      <div style={{ opacity: showContent ? 1 : 0 }}>{children}</div>
      <Wrapper ref={wrapperRef}>
        <Box ref={boxRef}>
          <InnerClip>
            <div>
              <Details />
            </div>
            <Marquee>
              <div>
                LOADING THE SITE
                <PreloaderMark />
              </div>
              <div>
                LOADING THE SITE
                <PreloaderMark />
              </div>
              <div>
                LOADING THE SITE
                <PreloaderMark />
              </div>
              <div>
                LOADING THE SITE
                <PreloaderMark />
              </div>
            </Marquee>
            <FilledMarquee ref={filledMarquee}>
              <div>
                LOADING THE SITE
                <PreloaderMark />
              </div>
              <div>
                LOADING THE SITE
                <PreloaderMark />
              </div>
              <div>
                LOADING THE SITE
                <PreloaderMark />
              </div>
              <div>
                LOADING THE SITE
                <PreloaderMark />
              </div>
            </FilledMarquee>
          </InnerClip>
        </Box>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.base500};
  z-index: 100;
  display: grid;
  place-items: center;
  ${textStyles.h3}
  opacity: 1;
  -webkit-text-stroke: 1px ${colors.base200};
  -moz-text-stroke: 1px ${colors.base200};
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  ${fresponsive(css`
    font-weight: 900;
    font-size: 74px;
  `)}
  ${fmobile(css`
    font-size: 50px;
  `)}
`

const Box = styled.div`
  opacity: 0;
  border: 1px solid ${colors.base200};
  overflow: hidden;
`

const InnerClip = styled.div`
  position: relative;
  display: flex;
  ${fresponsive(css`
    width: calc(100vw - 200px);
    height: 110px;
  `)}
  ${ftablet(css`
    width: calc(100vw - 180px);
  `)}
  ${fmobile(css`
    width: calc(100vw - 60px);
    height: 659px;
    max-height: calc(100vh - 200px);
    display: grid;
    grid-template-rows: 1fr 110px;
    place-items: center;
  `)}
`

const MarqueeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  
  100% {
    transform: translateX(-100%);
  }
`

const SpinAnimation = keyframes`
  0% {  
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const Details = styled(DetailsSVG)`
  animation: ${SpinAnimation} 5s linear infinite;

  ${fresponsive(css`
    height: 90px;
    width: 90px;
    margin: 10px;
  `)}

  ${fmobile(css`
    width: 166px;
    height: 166px;
    margin: 0 0 -25px;
  `)}

  * {
    fill: ${colors.base300};
  }
`

const Marquee = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: pre;
  align-items: baseline;
  border-left: 1px solid ${colors.base200};
  overflow: hidden;
  width: 100%;

  > div {
    animation: ${MarqueeAnimation} 3s linear infinite;
  }

  ${fresponsive(css`
    padding: 22px 0 0;
  `)}

  ${media.mobile} {
    border-left: none;
    border-top: 1px solid ${colors.base200};
  }
`

const PreloaderMark = styled(PreloaderMarkSVG)`
  ${fresponsive(css`
    height: 54px;
    margin: 0 20px;
  `)}
  ${fmobile(css`
    height: 36px;
    margin: 0 13px;
  `)}
`

const FilledMarquee = styled(Marquee)`
  ${fresponsive(css`
    position: absolute;
    left: 110px;
  `)}
  ${fmobile(css`
    left: 50%;
    bottom: 19px;
    transform: translate(-50%, 0);
  `)}
  --text-color: white;

  color: var(--text-color);
  -webkit-text-stroke: 1px var(--text-color);
  -moz-text-stroke: 1px var(--text-color);
  -webkit-text-fill-color: var(--text-color);
  -moz-text-fill-color: var(--text-color);
  clip-path: circle(0 at 50% 150%);
`
