import { ScreenProvider } from "../../library/ScreenContext"
import { FontsProvider } from "./fonts"
import { NavProvider } from "./Nav"
import { NextCaseProvider } from "./NextCase"

interface ProvidersProps {
  children: React.ReactNode
}

export default function Providers({ children }: ProvidersProps) {
  return (
    <FontsProvider>
      <NextCaseProvider>
        <ScreenProvider>
          <NavProvider>{children}</NavProvider>
        </ScreenProvider>
      </NextCaseProvider>
    </FontsProvider>
  )
}
