import fullyResponsive from "library/fullyResponsive"
import { css } from "styled-components"

const textStyles = {
  h0Point5: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 230px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  h1: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 180px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  h2: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 120px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  h3: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  h4: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  h5: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  h6: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 25px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  h7: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 100%;
  `),
  h8: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 250;
    font-size: 15px;
    line-height: 140%;
    letter-spacing: -0.02em;
  `),
  body1: fullyResponsive(css`
    font-family: Roobert, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 178%;
  `),
  body2: fullyResponsive(css`
    font-family: Roobert, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
  `),
  body3: fullyResponsive(css`
    font-family: Roobert, sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
  `),
  overline: fullyResponsive(css`
    font-family: Roobert, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
  `),
  digital0: fullyResponsive(css`
    font-family: "VCR OSD Mono", monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;
  `),
  digital1: fullyResponsive(css`
    font-family: "VCR OSD Mono", monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    text-transform: uppercase;
  `),
  digital2: fullyResponsive(css`
    font-family: "VCR OSD Mono", monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    text-transform: uppercase;
  `),
  primaryLink: fullyResponsive(css`
    font-family: "Monument Extended", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  `),
  navLink: fullyResponsive(css`
    font-family: Roobert, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  `),
  footerLink: fullyResponsive(css`
    font-family: Roobert, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: -0.02em;
  `),
  // h1Tablet: css`
  //   font-family: 'Monument Extended';
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 40px;
  //   line-height: 120%;
  // `,
  // h1Mobile: css`
  //   font-family: 'Monument Extended';
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 100px;
  //   line-height: 100%;
  //   letter-spacing: -0.02em;
  // `
}

export const strokeText = css`
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  -webkit-text-stroke-width: 0.07vw;
`

export const transparentText = css`
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-clip: text;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-background-clip: text;
`

export default textStyles
