import { createContext, useEffect, useState } from "react"

export const FontsContext = createContext<boolean>(false)

interface Props {
  children: React.ReactNode
}

export function FontsProvider({ children }: Props) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    document.fonts.ready
      .then(() => {
        return setLoaded(true)
      })
      .catch(() => {
        console.error("fonts not loading yo")
      })
  }, [setLoaded])

  return (
    <FontsContext.Provider value={loaded}>{children}</FontsContext.Provider>
  )
}
